.chat-container {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 8rem;
  background-color: #E5E5E5;
}
.chat-container * {
  font-family: "SFProDisplay-Regular", sans-serif;
}
.chat-message {
  padding: 14px;
  font-style: italic;
  color: #782EB1;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}
.chat-input-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 5px;
}
.input {
  border-radius: 50px;
  border: 1px solid #e3e3e3;
  /* box-shadow: 0 1px 11px #0000004d; */
  width: calc(100% + -72px);
  margin: 5px 0 0 8px;
  float: left;
}
.input > input {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  padding: 2px 3rem 2px 1rem;
  border: 1px solid #e3e3e3;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}
.input * {
  outline: none;
  border: none;
  font-size: 1.2rem;
  /* caret-color: rgb(232, 97, 0); */
}
.input *::placeholder {
  color: #999999;
  opacity: 0.5;
}
.input > input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 0.5; /* Firefox */
}

.input > input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
  opacity: 0.5;
}

.input > input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999999;
  opacity: 0.5;
}
.btn-send {
  float: right;
  margin: 6px 11px 0 0;
}

.input-counter {
  font-size: small;
  color: #9c9c9c;
  position: absolute;
  right: 72px;
  bottom: 12px;
  width: 2rem;
}

.btn-send-icon:first-child {
  fill: url(#paint0_grey) #C1C1C1;
}

.btn-send-icon:first-child.active {
  fill: url(#paint0_active) #782EB1;
}
