@font-face {
  font-family: "SFProDisplay-Regular";
  font-style: normal;
  font-display: swap;
  src: url(./fonts/sf-pro-display-regular.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
}

html {
  background-color: #e3e3e3;
  font-family: "SFProDisplay-Regular", sans-serif;
}

body {
  margin: 0;
  padding-bottom: 3rem;
}

.centre {
  text-align: center;
  color: rgb(59, 58, 58);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toastr {
  position: absolute;
  bottom: 8.2rem;
  margin: auto;
  height: 3rem;
  padding: 8px;
  display: none;
  visibility: collapse;
  background: white;
  width: 15rem;
  left: 0;
  right: 0;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
}
.toastr.show {
  display: block;
  visibility: visible;
}
.toastr > span {
  margin: 0 10px;
  position: relative;
  top: -9px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
}
