.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.sidebar {
  background-color: #fff;
  opacity: 1;
  font-family: "SFProDisplay-Regular", sans-serif;
  z-index: 100;
  position: fixed;
  height: 118px;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}
.sidebar-navigation {
  padding: 52px 0;
}
.sidebar-cruise {
  padding: 20px 0;
}

.sidebar.pause {
  color: #f26415;
}

.marker {
  background-image: url("../../images/drive-marker.svg");
  background-size: cover;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}
.marker-inactive {
  background-image: url("../../images/drive-marker-inactive.svg");
  background-size: cover;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup-content, .mapboxgl-popup-tip {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.dest-popup .mapboxgl-popup-content {
  font-family: "SFProDisplay-Regular", sans-serif;
  border-radius: 10%;
}
.dest-popup .mapboxgl-popup-content > strong {
  font-size: 18px;
  font-weight: 600;
}
.dest-popup .mapboxgl-popup-content > p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #9c9c9c;
}
.dest-popup .mapboxgl-popup-content > span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #782eb1;
}

.share-live-location {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}

.share-live-location > .item-text {
  flex-grow: 3;
}
.share-live-location > .item-text > span {
  color: #778188;
}

.live-location-marker {
  background-image: url("../../images/car-marker.svg");
  background-size: cover;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
}

.share-live-location > .item-icon {
  margin-top: 4px;
  height: 32px;
  width: 32px;
  background: url("../../images/share-location.svg") bottom center;
}

.share-live-location > .item-toggle {
  padding: 5px 0;
}

.react-toggle--checked .react-toggle-track,
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track,
.react-toggle-track {
  background: white;
  width: 45px;
  height: 22px;
  border: #c1c1c1 1px solid;
}

.react-toggle-thumb {
  width: 18px;
  height: 18px;
  top: 2px;
  border: #c1c1c1 1px solid;
  background-color: #c4c4c4;
}
.react-toggle--checked .react-toggle-thumb {
  background-color: #15b765;
}
.react-toggle--checked .react-toggle-thumb {
  left: 25px;
}
